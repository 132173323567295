module services {
    export module lc {
        export class letterOfCreditService implements interfaces.lc.ILetterOfCreditService {
            static $inject = ["$resource", "ENV", "$http", "$timeout", "generalService"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig, private $http: ng.IHttpService, private $timeout: ng.ITimeoutService, public generalService: interfaces.applicationcore.IGeneralService) {
            }

            getList(): ng.resource.IResourceClass<interfaces.lc.ILetterOfCreditDisplay> {
                return this.$resource<interfaces.lc.ILetterOfCreditDisplay>(this.ENV.DSP_URL + "LetterOfCredit/GetList", {
                    connectSearch: '@connectSearch',
                    numberRecords: '@numberRecords',
                    pageNumber: '@pageNumber',
                }, {
                        query: {
                            method: 'GET',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                            params: {
                                numberRecords: 25,
                                pageNumber: 1,
                            }
                        }
                    });
            }

            getLCListExcel(params: any): ng.IPromise<void> {
                var pdfDownload = document.createElement("a");
                document.body.appendChild(pdfDownload);

                var url = this.ENV.DSP_URL + 'LetterOfCredit/GetLCListExcel?';

                var searchObject: interfaces.applicationcore.ISearchObject = {
                    filters: [],
                    sorts: []
                }
                searchObject = params.connectSearch;

                url += 'connectSearch=' + btoa(JSON.stringify(searchObject));

                return this.$http({
                    method: 'GET',
                    responseType: 'arraybuffer',
                    url: url,
                }).then((response) => {
                    var filename = response.headers()['x-filename'] || 'LCDownload.xlsx';
                    var fileBlob = new Blob([response.data as BlobPart], { type: 'application/octet-stream' });

                    if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE browser
                        window.navigator.msSaveBlob(fileBlob, filename);
                    } else { // for other browsers
                        var fileURL = window.URL.createObjectURL(fileBlob);
                        pdfDownload.href = fileURL;
                        pdfDownload.download = filename;
                        pdfDownload.click();
                        pdfDownload.remove();
                    }

                    this.$timeout(() => {
                        this.generalService.displayMessage("Your download has started.", Enum.EnumMessageType.Success);
                    });
                }).catch((errorResponse) => {
                    var fileBlob = new Blob([errorResponse.data], { type: 'application/json' });
                    var fileURL = window.URL.createObjectURL(fileBlob);
                    const reader = new FileReader();

                    // This fires after the blob has been read/loaded.
                    reader.addEventListener('loadend', (e: ProgressEvent) => {
                        const text = e.target['result'];

                        this.$timeout(() => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>JSON.parse(text));
                        });
                    });

                    // Start reading the blob as text.
                    reader.readAsText(fileBlob);
                });
            }

            getLetterOfCredit(Id: number): ng.resource.IResourceClass<interfaces.lc.ILetterOfCredit> {
                return this.$resource<interfaces.lc.ILetterOfCredit>(this.ENV.DSP_URL + "LetterOfCredit/GetLetterOfCredit", {
                    Id: Id
                }, {
                    query: {
                        method: 'GET',
                        isArray: false,
                        interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                    }
                });
            }
           
            refreshBankingDetails(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "LetterOfCredit/RefreshBankingDetails", {
                    letterOfCreditId: '@letterOfCreditId'
                },
                    {
                        save: {
                            method: 'POST'
                        }
                    }
                );
            }

            save(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "LetterOfCredit/Save");
            }

            changeStatus(): ng.resource.IResourceClass<interfaces.lc.ILetterOfCredit> {
                return this.$resource<interfaces.lc.ILetterOfCredit>(this.ENV.DSP_URL + "LetterOfCredit/ChangeStatus", {
                }, {
                    save: {
                        method: 'POST'
                    }
                });
            }
            CreateNewVersion(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "LetterOfCredit/CreateNewVersion", {
                    id: '@id',
                    functionClassificationValueId: '@functionClassificationValueId',
                    reason: '@reason'
                });
            }

            getCurrencyDropdownForSupplier(supplierId: number): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {
                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "LetterOfCredit/getCurrencyDropdownForSupplier", {
                    supplierId: supplierId
                });
            }

            getCreateLetterOfCreditDefaults(ownerEntityId: number, supplierId: number): ng.resource.IResourceClass<interfaces.lc.ILetterOfCreditCreateDefaults> {
                return this.$resource<interfaces.lc.ILetterOfCreditCreateDefaults>(this.ENV.DSP_URL + "LetterOfCredit/GetCreateLetterOfCreditDefaults",
                    {
                        ownerEntityId: ownerEntityId,
                        supplierId: supplierId
                    }, {
                    get: {
                        method: 'GET',
                        isArray: false,
                        interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                    }
                }
                );

            }

            getPurchaseOrdersForSupplier(ownerEntityId: number, supplierId: number, currencyId: number, creditTypeId: number, incotermId: number, letterOfCreditId: number): ng.resource.IResourceClass<interfaces.lc.IPurchaseOrderLinesDisplay> {
                return this.$resource<interfaces.lc.IPurchaseOrderLinesDisplay>(this.ENV.DSP_URL + "LetterOfCredit/GetPurchaseOrdersForSupplier", {
                    ownerEntityId: ownerEntityId,
                    supplierId: supplierId,
                    currencyId: currencyId,
                    creditTypeId: creditTypeId,
                    incotermId: incotermId,
                    letterOfCreditId: letterOfCreditId
                });
            }

            createLCFromPOLines(ownerEntityId: number, supplierId: number, currencyId: number, incotermId: number, creditTypeId: number, isCustomLCApplicantRef: boolean, customLCApplicantRef: string, lcCounterId: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {

                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "LetterOfCredit/CreateLCFromPOLines", {
                    ownerEntityId: ownerEntityId,
                    supplierId: supplierId,
                    currencyId: currencyId,
                    incotermId: incotermId,
                    creditTypeId: creditTypeId,
                    isCustomLCApplicantRef: isCustomLCApplicantRef,
                    customLCApplicantRef: customLCApplicantRef,
                    lcCounterId: lcCounterId

                }, {
                    query: {
                        method: 'POST',
                        isArray: true,
                        interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                    }

                });
            }

            updateLCFromPurchaseOrderLines(letterOfCreditId: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {

                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "LetterOfCredit/UpdateLCFromPurchaseOrderLines", {
                    letterOfCreditId: letterOfCreditId
                }, {
                    query: {
                        method: 'POST',
                        isArray: true,
                        interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                    }

                });
            }

            refreshFromPurchaseOrder(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "LetterOfCredit/RefreshFromPurchaseOrder", {
                    lcId: '@lcId',
                    entityId: '@entityId'
                },
                    {
                        save: {
                            method: 'POST'
                        }
                    }
                );
            }

            getSupplierDropdownLists(ownerEntityId: number): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {
                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "LetterOfCredit/GetSupplierDropdownLists", {
                    ownerEntityId: ownerEntityId
                });
            }

            generateAddendum(letterOfCreditId: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "LetterOfCredit/GenerateAddendum", {
                    letterOfCreditId: letterOfCreditId
                },
                    {
                        save: {
                            method: 'POST'
                        }
                    }
                );
            }

            generateAmendmentAddendum(letterOfCreditId: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "LetterOfCredit/GenerateAmendmentAddendum", {
                    letterOfCreditId: letterOfCreditId
                },
                    {
                        save: {
                            method: 'POST'
                        }
                    }
                );
            }
        }
    }

    angular.module("app").service("letterOfCreditService", services.lc.letterOfCreditService);
}